import { createContext, useState, ReactNode, ReactElement } from "react";

const UserOperatingSystemContext = createContext<{
    operatingSystem: string | null;
}>({
    operatingSystem: null,
});

export const UserOperatingSystemContextProvider = ({
    userOperatingSystem,
    children,
}: {
    userOperatingSystem: string | null | undefined;
    children: ReactNode;
}): ReactElement => {
    const [operatingSystem] = useState<string | null>(userOperatingSystem);

    const context = {
        operatingSystem,
    };

    return (
        <UserOperatingSystemContext.Provider value={context}>
            {children}
        </UserOperatingSystemContext.Provider>
    );
};

export default UserOperatingSystemContext;
