import React, { ReactElement, ReactNode } from "react";
import Head from "next/head";

import dynamic from "next/dynamic";

import Header from "@components/shared/header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/reducers";
import { getCookie, setCookie } from "cookies-next";
import { setModalActive } from "@redux/vpnpricecomparison/actions";
import Mobile from "@components/shared/blogPost/sideWidget/mobile";
import { setModal } from "@redux/shared/actions";

import Schema from "@components/scripts/schema";
import { useDomainContext } from "@hooks/useDomainContext";

const Footer = dynamic(() => import("@components/antiviruschoice/footer"));
const Modal = dynamic(() => import("@components/shared/modal"));
const Popup = dynamic(() => import("@components/antiviruschoice/popup"));
const CloseIcon = dynamic(() => import("@components/svg/closeIcon"));

export default function AntiVirusChoiceWrapper({
    children,
}: {
    children: ReactNode;
}): ReactElement {
    const dispatch = useDispatch();

    const { domainPopup } = useDomainContext();

    const modalActive = useSelector(
        (state: RootState) => state.rootReducer.vpnpricecomparison.modal.active,
    );
    const { active: modalActiveBlogPost } = useSelector(
        (state: RootState) => state.rootReducer.shared.modal,
    );
    const handleClose = () => {
        dispatch(setModal({ active: false, content: undefined }));
    };

    const handleMouseLeave = () => {
        if (
            document.activeElement?.tagName === "INPUT" ||
            document.activeElement?.tagName === "SELECT"
        )
            return;

        if (!getCookie("popup-shown")) {
            dispatch(setModalActive(true));
            setCookie("popup-shown", "true");
        }
    };

    const handleModalClose = () => {
        dispatch(setModalActive(false));
    };

    return (
        <>
            <Head>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                    crossOrigin="true"
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,900;1,800&display=swap"
                    rel="stylesheet"
                />
                <Schema
                    url="https://antiviruschoice.com"
                    name="AntiVirus Choice"
                />
            </Head>
            <div
                onMouseLeave={handleMouseLeave}
                style={{
                    fontFamily: `'Open Sans', sans-serif`,
                }}
            >
                <Header />
                <main className="max-w-7xl mx-auto block px-4vw lg:px-4">
                    {children}
                </main>
                <Footer />
                {domainPopup && (
                    <Modal
                        onClose={handleModalClose}
                        active={modalActive}
                        closeIcon={<CloseIcon />}
                        backdropClosable
                        modalClassName="h-[90vh] lg:h-500px rounded"
                        wrapperClassName="flex items-center"
                        modalStyles={{ fontFamily: `'Poppins', sans-serif` }}
                        bodyStyles={{ height: "100%" }}
                        width={900}
                    >
                        <Popup data={domainPopup} />
                    </Modal>
                )}
                <Modal
                    onClose={handleClose}
                    active={modalActiveBlogPost}
                    closeIcon={<CloseIcon />}
                    backdropClosable
                    modalStyles={{
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: "80vh",
                    }}
                    modalClassName="h-[90vh] rounded"
                    bodyStyles={{ overflowY: "scroll" }}
                    width={900}
                    header="Contents"
                >
                    <Mobile />
                </Modal>
            </div>
        </>
    );
}
