import AffiliateOfferUrl from "@components/shared/affiliateOfferUrl";
import { AffiliateOfferZone } from "@lib/shared/config";
import { ListingItem } from "@lib/shared/types";
import React, { useContext } from "react";
import UserOperatingSystemContext from "src/stores/antiviruschoice/operatingSystem";
import styles from "./bestAntivirusRow.module.scss";
interface Props {
    comparison: boolean;
    title?: string | null | undefined;
    offer?: ListingItem | undefined;
    gradient?: boolean;
    zone: AffiliateOfferZone;
}
const BestAntivirusRow: React.FC<Props> = ({
    comparison,
    title,
    offer,
    gradient,
    zone,
}) => {
    const { operatingSystem } = useContext(UserOperatingSystemContext);

    return (
        <div
            className={`w-full flex justify-center md:justify-between pb-12 pt-12 px-4 ${
                comparison
                    ? `${styles["text-black"]} text-blue-500`
                    : styles["text-white"]
            } ${gradient ? styles["bg-bottom-gradient"] : ""}`}
        >
            <div className="hidden md:flex mr-8 flex-col justify-center md:w-10/12">
                <h1 className="font-bold text-4xl">
                    {title ??
                        `${
                            comparison
                                ? `The Best Antivirus Protection Software of ${new Date().getFullYear()}`
                                : `The Best Antivirus Solutions of ${new Date().getFullYear()}`
                        }`}
                </h1>
                <p>
                    {comparison
                        ? "Our team tested 50 antivirus software providers, and these were the ones that stood out."
                        : `There's no compromising when it comes to cybersecurity! That's why our cybersecurity experts have tested over 60 antivirus solutions from 2018 to ${new Date().getFullYear()}. Their goal? To identify cutting-edge technologies that offer unparalleled protection against various threats, including viruses, spyware, and Trojans.`}
                </p>
                <div className="flex items-center mt-12 font-bold">
                    <p className="mr-4">Compatible with:</p>
                    <ul className="flex">
                        <li>
                            <img
                                src={`/assets/${
                                    !comparison ? "dark" : "light"
                                }/apple.svg`}
                                alt="mac"
                                className="mr-2"
                            />
                        </li>
                        <li>
                            <img
                                src={`/assets/${
                                    !comparison ? "dark" : "light"
                                }/androidb.svg`}
                                alt="android"
                                className="mr-2"
                            />
                        </li>
                        <li>
                            <img
                                src={`/assets/${
                                    !comparison ? "dark" : "light"
                                }/ios.svg`}
                                alt="ios"
                                className="mr-2"
                            />
                        </li>
                        <li>
                            <img
                                src={`/assets/${
                                    !comparison ? "dark" : "light"
                                }/windowsOs.svg`}
                                alt="windows"
                                className="mr-2"
                            />
                        </li>
                    </ul>
                </div>
            </div>
            <div className="flex flex-col md:w-5/12">
                <div className="flex flex-col md:flex-row mb-4 items-center">
                    {operatingSystem && (
                        <img
                            src={`/assets/${
                                comparison ? "dark" : "light"
                            }/${operatingSystem}.svg`}
                            alt=""
                            className="mr-2"
                        />
                    )}
                    <h1
                        className={`w-80 md:w-full text-center md:text-left font-bold text-xl md:text-lg xl:text-xl lg:ml-2 ${
                            comparison ? styles.blue : ""
                        }`}
                    >
                        Get the best Antivirus Software for {operatingSystem}
                    </h1>
                </div>
                <div
                    className={`flex flex-col items-center ${
                        comparison ? styles.gray : ""
                    } text-center md:text-left `}
                >
                    <p className="max-w-sm">
                        {offer?.title} Antivirus: {operatingSystem}'s leading
                        security and antivirus software choice for the past 25
                        years.
                    </p>
                    <button
                        className={`w-72 md:w-full py-3 px-4 rounded font-bold ${styles.button} relative mt-4`}
                    >
                        <AffiliateOfferUrl
                            offer={offer}
                            className="w-full h-full"
                            zone={zone}
                        >
                            Download the best antivirus
                        </AffiliateOfferUrl>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default BestAntivirusRow;
